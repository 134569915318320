@media (max-width: 575.98px) {
  .navmini {
    display: true;
  }
}

@media (min-width: 575.98px) {
  .navmini {
    display: none;
  }
}

.link{
  color: #000;
}

.link:hover{
  color: blue;
}

.avatar {
  vertical-align: middle;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border-width: 50%;
  border-color: #000;
  border-style: outset;
}
